import React from 'react';

import { FiSliders } from 'react-icons/fi';

import { Container, 
         Title, 
         Icon, 
         Images,
         NotebookWrap,
         ImageWrap } from './styles';

import BackofficeImage from '../../images/gatsby-backoffice-image.png';
import BackofficeNotebookImage from '../../images/gatsby-backoffice-notebook.png';

const Backoffice = () => {
    return (
        <>
        <Container>

            <Images>
                <NotebookWrap  src={BackofficeNotebookImage} />
                
                <ImageWrap src={BackofficeImage} />
            </Images>
            
            <Title>
                <Icon>
                    <FiSliders size={40} />
                </Icon>

                <h1>Portal do corretor</h1>
                <p>Com o portal, a imobiliária gerencia e acompanha as comissões de forma fácil e integrada.</p>
            </Title>

        </Container>
        </>       
    )
}

export default Backoffice