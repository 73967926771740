import styled from 'styled-components';

export const Container = styled.section`
    background: #FFF;
    height: auto;

    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-template-areas: "image title";
    

    @media (max-width: 768px) {
      
      grid-template-columns: 100%;

      max-width: 375px;
    }
    
`;

export const Images = styled.div`
    grid-area: 'image';

    width: 100%;
    max-height: 100%;
    object-fit: cover;

    @media (max-width: 768px) {
        max-height: 100%;
        position: absolute;
    }
    
`;

export const ImageWrap = styled.img`
    margin: 0 auto;
    float:left;

    @media (max-width: 768px) {
        z-index:0;
        display: none;
    }
`;

export const NotebookWrap = styled.img`
   position: absolute;
   margin: 7vh 23vh;
   
   @media (max-width: 500px) {
        position: absolute;
        width:90%;
        margin: auto 3vh;
   }
`;

export const Title = styled.div`
    grid-area: 'title';
    align-self: center;
    justify-self: center;
    max-width: 490px;
    
    h1 {
        font-family: "Avenir", Avenir, Montserrat;;
        font-style: normal;
        font-weight: 900;
        font-size: 56px;
        line-height: 56px;
        color: #0E222B;
    }

    p {
        max-width: 329px;
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 25px;
        color: #ADB5BD;
        margin-top: 20px;
    }

    @media (max-width: 768px) {
      z-index:2;
      margin-left: 6vh;
      margin-top: 27vh;
      margin-bottom: 5vh;
      max-width: 309px;

        h1 {
            font-size: 40px;
        }
        
        p {
            margin-right: 20px;
        }
    }

    @media (max-width: 375px) {
        margin-top: 22vh;
    }
    
`;

export const Icon = styled.div`

    background: #80F5E4;
    border-radius: 32px;
    width: 99px;
    height: 99px;
    text-align: center;
    margin-bottom: 20px;

    svg{
        position:relative;
        top: calc(50% - 20px);
    }
`;



